import Vue from 'vue';

export default {
	name: 'FloatingInput',
	props: {
	value: {
		type: [String, Number],
		default: ''
	},
	label: {
		type: String,
		required: true
	},
	shortLabel: {
		type: String,
		default: ''
	},
	tooltip: {
		type: String,
		default: ''
	},
	type: {
		type: String,
		default: 'text'
	},
	required: {
		type: Boolean,
		default: false
	},
	disabled: {
		type: Boolean,
		default: false
	},
	inputmode: {
		type: String,
		default: undefined
	},
	minlength: {
		type: [String, Number],
		default: undefined
	},
	maxlength: {
		type: [String, Number],
		default: undefined
	},
	isTextarea: {
		type: Boolean,
		default: false
	},
	name: {
		type: String,
		required: false
	}
	},
	data() {
		return {
			hasValue: !!this.value
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(newValue) {
				this.hasValue = !!newValue
			}
		}
	},
	methods: {
		updateValue(value) {
			this.$emit('input', value)
			this.hasValue = !!value
		},
		handleBlur(event) {
			this.$emit('blur', event)
			this.hasValue = !!event.target.value
		},
		handleFocus(event) {
			this.$emit('focus', event)
			this.hasValue = true
		}
	}
}