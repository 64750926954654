var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form", { attrs: { method: "post" }, on: { submit: _vm.send } }, [
    _vm.message
      ? _c("div", [
          _c(
            "div",
            { staticClass: "alert alert-danger", attrs: { role: "alert" } },
            [
              _c("span", { staticClass: "a-minor" }, [
                _vm._v(_vm._s(_vm.message)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("br"),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.data.registered
      ? _c("div", { staticClass: "order-section" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "order-section-content" }, [
            _c("br"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                'Вы наконец добрались до оформления заказа и отправки его нам для дальнейшей обработки. Для этого достаточно заполнить поля на форме ниже и нажать кнопку "Оформить заказ".'
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Вы можете это сделать без регистрации на нашем сайте, но и вам и нам будет удобнее если вы все-таки зарегистрируетесь. Вы сможете увидеть статус обработки вашего заказа, да и при следующем заказе не нужно будет вводить большую часть данных."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btn-sm",
                  attrs: { href: _vm.register },
                },
                [
                  _c("i", { staticClass: "fa fa-edit" }),
                  _vm._v(" Зарегистрироваться"),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btn-sm",
                  attrs: { href: _vm.login },
                },
                [_c("i", { staticClass: "fa fa-sign-in" }), _vm._v(" Войти")]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "order-section order-contacts" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "order-section-content" }, [
        !_vm.data.registered
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col col-12 col-md-6 col-lg-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        label: "Имя",
                        required: "",
                        disabled: _vm.loading,
                        name: "contact[first_name]",
                      },
                      model: {
                        value: _vm.contact.first_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.contact, "first_name", $$v)
                        },
                        expression: "contact.first_name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 col-md-6 col-lg-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        label: "Фамилия",
                        required: "",
                        disabled: _vm.loading,
                        name: "contact[last_name]",
                      },
                      model: {
                        value: _vm.contact.last_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.contact, "last_name", $$v)
                        },
                        expression: "contact.last_name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 col-md-6 col-lg-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        type: "email",
                        label: "Адрес электронной почты",
                        required: "",
                        disabled: _vm.loading,
                        name: "contact[email]",
                      },
                      model: {
                        value: _vm.contact.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.contact, "email", $$v)
                        },
                        expression: "contact.email",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 col-md-6 col-lg-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        type: "tel",
                        label: "Контактный телефон",
                        required: "",
                        disabled: _vm.loading,
                        name: "contact[phone]",
                      },
                      model: {
                        value: _vm.contact.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.contact, "phone", $$v)
                        },
                        expression: "contact.phone",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _c("div", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "form-group col-md-10" }, [
                  _c("p", [
                    _c("strong", [_vm._v("Имя")]),
                    _vm._v(" " + _vm._s(_vm.data["contact"]["first_name"])),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("strong", [_vm._v("Фамилия")]),
                    _vm._v(" " + _vm._s(_vm.data["contact"]["last_name"])),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("strong", [_vm._v("Адрес электронной почты")]),
                    _vm._v(
                      " " + _vm._s(_vm.data["contact"]["email"] || "не указан")
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("strong", [_vm._v("Контактный телефон")]),
                    _vm._v(
                      " " + _vm._s(_vm.data["contact"]["phone"] || "не указан")
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              !_vm.data["contact"]["first_name"] ||
              !_vm.data["contact"]["last_name"] ||
              !_vm.data["contact"]["email"] ||
              !_vm.data["contact"]["phone"]
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-danger",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("span", { staticClass: "a-minor" }, [
                          _vm._v(
                            "\n                Для успешного оформления заказа просим Вас заполнить недостающие контактные данные перейдя по "
                          ),
                          _c("a", { attrs: { href: _vm.customer } }, [
                            _vm._v("ссылке "),
                            _c("i", {
                              staticClass: "fa fa-arrow-circle-right fa-lg",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                  ])
                : _vm._e(),
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col col-12 col-md-6 col-lg-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "radio-button-with-icon" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.pmethod,
                      expression: "form.pmethod",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    required: "",
                    name: "pmethod",
                    value: "C",
                  },
                  domProps: { checked: _vm._q(_vm.form.pmethod, "C") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.form, "pmethod", "C")
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(2),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col col-12 col-md-6 col-lg-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "radio-button-with-icon" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.pmethod,
                      expression: "form.pmethod",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    required: "",
                    name: "pmethod",
                    value: "W",
                    disabled: _vm.loading,
                  },
                  domProps: { checked: _vm._q(_vm.form.pmethod, "W") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.form, "pmethod", "W")
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(3),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "checkboxes" }, [
          _c("div", { staticClass: "checkbox" }, [
            _c("label", { staticClass: "checkbox__label" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.opt_email,
                    expression: "form.opt_email",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  name: "opt_email",
                  disabled: _vm.loading,
                },
                domProps: {
                  checked: Array.isArray(_vm.form.opt_email)
                    ? _vm._i(_vm.form.opt_email, null) > -1
                    : _vm.form.opt_email,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.form.opt_email,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.form, "opt_email", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.form,
                            "opt_email",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.form, "opt_email", $$c)
                    }
                  },
                },
              }),
              _vm._v(" "),
              _vm._m(4),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkbox" }, [
            _c("label", { staticClass: "checkbox__label" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.opt_fax,
                    expression: "form.opt_fax",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  name: "opt_fax",
                  disabled: _vm.loading,
                },
                domProps: {
                  checked: Array.isArray(_vm.form.opt_fax)
                    ? _vm._i(_vm.form.opt_fax, null) > -1
                    : _vm.form.opt_fax,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.form.opt_fax,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.form, "opt_fax", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.form,
                            "opt_fax",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.form, "opt_fax", $$c)
                    }
                  },
                },
              }),
              _vm._v(" "),
              _vm._m(5),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkbox" }, [
            _c("label", { staticClass: "checkbox__label" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.hascomment,
                    expression: "hascomment",
                  },
                ],
                attrs: { type: "checkbox", disabled: _vm.loading },
                domProps: {
                  checked: Array.isArray(_vm.hascomment)
                    ? _vm._i(_vm.hascomment, null) > -1
                    : _vm.hascomment,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.hascomment,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.hascomment = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.hascomment = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.hascomment = $$c
                    }
                  },
                },
              }),
              _vm._v(" "),
              _vm._m(6),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.hascomment
          ? _c("div", { staticClass: "form-group" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.comment,
                    expression: "form.comment",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  rows: "3",
                  name: "comment",
                  placeholder: "Дополнительные пожелания",
                  disabled: _vm.loading,
                },
                domProps: { value: _vm.form.comment },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "comment", $event.target.value)
                  },
                },
              }),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "order-section order-delivery" }, [
      _vm._m(7),
      _vm._v(" "),
      _c("div", { staticClass: "order-section-content" }, [
        _vm.data["addresses"].length > 0
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    attrs: {
                      "data-bind": "visible: $parent.addresses().length > 1",
                    },
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.address.id,
                            expression: "address.id",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { name: "address[id]", disabled: _vm.loading },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.address,
                                "id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.setAddress,
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("Новый адрес доставки"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.data["addresses"], function (item) {
                          return _c(
                            "option",
                            { domProps: { value: item["id"] } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    ["place", "street", "house", "office"]
                                      .filter(
                                        (key) =>
                                          (item[key] || "").trim().length > 0
                                      )
                                      .map(
                                        (key) =>
                                          (key === "house" ? "дом " : "") +
                                          item[key]
                                      )
                                      .join(", ")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col col-12 col-md-6 col-lg-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(_setup.FloatingInput, {
                  attrs: {
                    label: "Город или район, населенный пункт",
                    required: "",
                    disabled: _vm.loading,
                    name: "address[place]",
                  },
                  model: {
                    value: _vm.address.place,
                    callback: function ($$v) {
                      _vm.$set(_vm.address, "place", $$v)
                    },
                    expression: "address.place",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col col-12 col-md-6 col-lg-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(_setup.FloatingInput, {
                  attrs: {
                    label: "Регион",
                    required: "",
                    disabled: _vm.loading,
                    name: "address[region]",
                  },
                  model: {
                    value: _vm.address.region,
                    callback: function ($$v) {
                      _vm.$set(_vm.address, "region", $$v)
                    },
                    expression: "address.region",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col col-12 col-md-6 col-lg-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(_setup.FloatingInput, {
                  attrs: {
                    label: "Улица",
                    required: "",
                    disabled: _vm.loading,
                    name: "address[street]",
                  },
                  model: {
                    value: _vm.address.street,
                    callback: function ($$v) {
                      _vm.$set(_vm.address, "street", $$v)
                    },
                    expression: "address.street",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col col-12 col-md-6 col-lg-6" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col col-4 col-md-4 col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        label: "Дом, корпус, строение",
                        shortLabel: "Дом",
                        required: "",
                        disabled: _vm.loading,
                        name: "address[house]",
                      },
                      model: {
                        value: _vm.address.house,
                        callback: function ($$v) {
                          _vm.$set(_vm.address, "house", $$v)
                        },
                        expression: "address.house",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col col-4 col-md-4 col-lg-4" },
                [
                  _c(_setup.FloatingInput, {
                    attrs: {
                      label: "Офис, квартира",
                      shortLabel: "Офис",
                      disabled: _vm.loading,
                      name: "address[office]",
                    },
                    model: {
                      value: _vm.address.office,
                      callback: function ($$v) {
                        _vm.$set(_vm.address, "office", $$v)
                      },
                      expression: "address.office",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col col-4 col-md-4 col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        label: "Этаж",
                        inputmode: "numeric",
                        disabled: _vm.loading,
                        name: "address[floor]",
                      },
                      model: {
                        value: _vm.address.floor,
                        callback: function ($$v) {
                          _vm.$set(_vm.address, "floor", $$v)
                        },
                        expression: "address.floor",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col col-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(_setup.FloatingInput, {
                  attrs: {
                    label: "Комментарий по доставке",
                    disabled: _vm.loading,
                    name: "address[comment]",
                  },
                  model: {
                    value: _vm.address.comment,
                    callback: function ($$v) {
                      _vm.$set(_vm.address, "comment", $$v)
                    },
                    expression: "address.comment",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "order-section order-documents" }, [
      _vm._m(8),
      _vm._v(" "),
      _c("div", { staticClass: "order-section-content" }, [
        _c("div", { staticClass: "form-group" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.entity.etype,
                  expression: "entity.etype",
                },
              ],
              staticClass: "form-control",
              attrs: { disabled: _vm.loading },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.entity,
                      "etype",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                  _vm.setEntityType,
                ],
              },
            },
            _vm._l(_vm.entityTypes, function (item) {
              return _c("option", { domProps: { value: item["key"] } }, [
                _vm._v(_vm._s(item["name"])),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _vm.data["entities"].length > 0
          ? _c("div", { staticClass: "form-group" }, [
              _vm.entity.etype !== "P"
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.entity.id,
                          expression: "entity.id",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "entity[id]", disabled: _vm.loading },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.entity,
                              "id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.setEntity,
                        ],
                      },
                    },
                    [
                      _vm.entity.etype === "S"
                        ? _c("option", { attrs: { value: "" } }, [
                            _vm._v("Новый предприниматель "),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.entity.etype === "L"
                        ? _c("option", { attrs: { value: "" } }, [
                            _vm._v("Новое юридическое лицо "),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.data["entities"], function (item) {
                        return item["label"] &&
                          item["etype"] === _vm.entity.etype
                          ? _c("option", { domProps: { value: item["id"] } }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item["label"]) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e()
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.entity.etype !== "P"
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col col-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        label: "Наименование для документов",
                        required: "",
                        disabled: _vm.loading,
                        name: "entity[label]",
                      },
                      model: {
                        value: _vm.entity.label,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity, "label", $$v)
                        },
                        expression: "entity.label",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 col-md-4 col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        inputmode: "numeric",
                        minlength: _vm.entity.etype === "L" ? 10 : 12,
                        maxlength: _vm.entity.etype === "L" ? 10 : 12,
                        label: "ИНН",
                        required: "",
                        disabled: _vm.loading,
                        name: "entity[inn]",
                      },
                      model: {
                        value: _vm.entity.inn,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity, "inn", $$v)
                        },
                        expression: "entity.inn",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.entity.etype === "L"
                ? _c("div", { staticClass: "col col-12 col-md-4 col-lg-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(_setup.FloatingInput, {
                          attrs: {
                            inputmode: "numeric",
                            maxlength: "9",
                            label: "КПП",
                            required: "",
                            disabled: _vm.loading,
                            name: "entity[kpp]",
                          },
                          model: {
                            value: _vm.entity.kpp,
                            callback: function ($$v) {
                              _vm.$set(_vm.entity, "kpp", $$v)
                            },
                            expression: "entity.kpp",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    "col",
                    "col-12",
                    "col-md-4",
                    _vm.entity.etype === "L" ? "col-lg-4" : "col-lg-8",
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(_setup.FloatingInput, {
                        attrs: {
                          inputmode: "numeric",
                          minlength: _vm.entity.etype === "L" ? 13 : 15,
                          maxlength: _vm.entity.etype === "L" ? 13 : 15,
                          label: "ОГРН",
                          disabled: _vm.loading,
                          name: "entity[ogrn]",
                        },
                        model: {
                          value: _vm.entity.ogrn,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "ogrn", $$v)
                          },
                          expression: "entity.ogrn",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 col-md-4 col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        inputmode: "numeric",
                        minlength: "9",
                        maxlength: "9",
                        label: "БИК",
                        disabled: _vm.loading,
                        name: "entity[accbid]",
                      },
                      model: {
                        value: _vm.entity.accbid,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity, "accbid", $$v)
                        },
                        expression: "entity.accbid",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 col-md-8 col-lg-8" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        inputmode: "numeric",
                        minlength: "20",
                        maxlength: "20",
                        label: "Расчётный счет",
                        disabled: _vm.loading,
                        name: "entity[accnum]",
                      },
                      model: {
                        value: _vm.entity.accnum,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity, "accnum", $$v)
                        },
                        expression: "entity.accnum",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        label:
                          _vm.entity.etype === "L"
                            ? "Юридический адрес"
                            : "Адрес регистрации",
                        disabled: _vm.loading,
                        name: "entity[legaddr]",
                      },
                      model: {
                        value: _vm.entity.legaddr,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity, "legaddr", $$v)
                        },
                        expression: "entity.legaddr",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 checkboxes" }, [
                _vm.entity.etype === "L"
                  ? _c("div", { staticClass: "checkbox" }, [
                      _c("label", { staticClass: "checkbox__label" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.entityHasactaddr,
                              expression: "entityHasactaddr",
                            },
                          ],
                          attrs: { type: "checkbox", disabled: _vm.loading },
                          domProps: {
                            checked: Array.isArray(_vm.entityHasactaddr)
                              ? _vm._i(_vm.entityHasactaddr, null) > -1
                              : _vm.entityHasactaddr,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.entityHasactaddr,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.entityHasactaddr = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.entityHasactaddr = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.entityHasactaddr = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(9),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12" }, [
                _vm.entity.etype === "L" && _vm.entityHasactaddr
                  ? _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(_setup.FloatingInput, {
                          attrs: {
                            label: "Фактический адрес",
                            disabled: _vm.loading,
                            name: "entity[actaddr]",
                          },
                          model: {
                            value: _vm.entity.actaddr,
                            callback: function ($$v) {
                              _vm.$set(_vm.entity, "actaddr", $$v)
                            },
                            expression: "entity.actaddr",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 checkboxes" }, [
                _c("div", { staticClass: "checkbox" }, [
                  _c("label", { staticClass: "checkbox__label" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.diffconsignee,
                          expression: "diffconsignee",
                        },
                      ],
                      attrs: { type: "checkbox", disabled: _vm.loading },
                      domProps: {
                        checked: Array.isArray(_vm.diffconsignee)
                          ? _vm._i(_vm.diffconsignee, null) > -1
                          : _vm.diffconsignee,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.diffconsignee,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.diffconsignee = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.diffconsignee = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.diffconsignee = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm._m(10),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _vm.entity.etype !== "P" && _vm.diffconsignee
      ? _c("div", { staticClass: "order-section order-documents" }, [
          _vm._m(11),
          _vm._v(" "),
          _c("div", { staticClass: "order-section-content" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.consignee.etype,
                      expression: "consignee.etype",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { disabled: _vm.loading },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.consignee,
                        "etype",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(
                  _vm.entityTypes.filter((e) => e.key !== "P"),
                  function (item) {
                    return _c("option", { domProps: { value: item["key"] } }, [
                      _vm._v(_vm._s(item["name"])),
                    ])
                  }
                ),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col col-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        label: "Наименование для документов",
                        required: "",
                        disabled: _vm.loading,
                        name: "consignee[label]",
                      },
                      model: {
                        value: _vm.consignee.label,
                        callback: function ($$v) {
                          _vm.$set(_vm.consignee, "label", $$v)
                        },
                        expression: "consignee.label",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 col-md-4 col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        inputmode: "numeric",
                        minlength: _vm.consignee.etype === "L" ? 10 : 12,
                        maxlength: _vm.consignee.etype === "L" ? 10 : 12,
                        label: "ИНН",
                        required: "",
                        disabled: _vm.loading,
                        name: "consignee[inn]",
                      },
                      model: {
                        value: _vm.consignee.inn,
                        callback: function ($$v) {
                          _vm.$set(_vm.consignee, "inn", $$v)
                        },
                        expression: "consignee.inn",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.consignee.etype === "L"
                ? _c("div", { staticClass: "col col-12 col-md-4 col-lg-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(_setup.FloatingInput, {
                          attrs: {
                            inputmode: "numeric",
                            maxlength: "9",
                            label: "КПП",
                            disabled: _vm.loading,
                            name: "consignee[kpp]",
                          },
                          model: {
                            value: _vm.consignee.kpp,
                            callback: function ($$v) {
                              _vm.$set(_vm.consignee, "kpp", $$v)
                            },
                            expression: "consignee.kpp",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    "col",
                    "col-12",
                    "col-md-4",
                    _vm.consignee.etype === "L" ? "col-lg-4" : "col-lg-8",
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(_setup.FloatingInput, {
                        attrs: {
                          inputmode: "numeric",
                          minlength: _vm.entity.etype === "L" ? 13 : 15,
                          maxlength: _vm.entity.etype === "L" ? 13 : 15,
                          label: "ОГРН",
                          disabled: _vm.loading,
                          name: "consignee[ogrn]",
                        },
                        model: {
                          value: _vm.consignee.ogrn,
                          callback: function ($$v) {
                            _vm.$set(_vm.consignee, "ogrn", $$v)
                          },
                          expression: "consignee.ogrn",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 col-md-4 col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        inputmode: "numeric",
                        minlength: "9",
                        maxlength: "9",
                        label: "БИК",
                        disabled: _vm.loading,
                        name: "consignee[accbid]",
                      },
                      model: {
                        value: _vm.consignee.accbid,
                        callback: function ($$v) {
                          _vm.$set(_vm.consignee, "accbid", $$v)
                        },
                        expression: "consignee.accbid",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 col-md-8 col-lg-8" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        inputmode: "numeric",
                        minlength: "20",
                        maxlength: "20",
                        label: "Расчётный счет",
                        disabled: _vm.loading,
                        name: "consignee[accnum]",
                      },
                      model: {
                        value: _vm.consignee.accnum,
                        callback: function ($$v) {
                          _vm.$set(_vm.consignee, "accnum", $$v)
                        },
                        expression: "consignee.accnum",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(_setup.FloatingInput, {
                      attrs: {
                        label:
                          _vm.consignee.etype === "L"
                            ? "Юридический адрес"
                            : "Адрес регистрации",
                        disabled: _vm.loading,
                        name: "consignee[legaddr]",
                      },
                      model: {
                        value: _vm.consignee.legaddr,
                        callback: function ($$v) {
                          _vm.$set(_vm.consignee, "legaddr", $$v)
                        },
                        expression: "consignee.legaddr",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 checkboxes" }, [
                _vm.consignee.etype === "L"
                  ? _c("div", { staticClass: "checkbox" }, [
                      _c("label", { staticClass: "checkbox__label" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.consigneeHasactaddr,
                              expression: "consigneeHasactaddr",
                            },
                          ],
                          attrs: { type: "checkbox", disabled: _vm.loading },
                          domProps: {
                            checked: Array.isArray(_vm.consigneeHasactaddr)
                              ? _vm._i(_vm.consigneeHasactaddr, null) > -1
                              : _vm.consigneeHasactaddr,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.consigneeHasactaddr,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.consigneeHasactaddr = $$a.concat([
                                      $$v,
                                    ]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.consigneeHasactaddr = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.consigneeHasactaddr = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(12),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12" }, [
                _vm.consignee.etype === "L" && _vm.consigneeHasactaddr
                  ? _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(_setup.FloatingInput, {
                          attrs: {
                            label: "Фактический адрес",
                            disabled: _vm.loading,
                            name: "consignee[actaddr]",
                          },
                          model: {
                            value: _vm.consignee.actaddr,
                            callback: function ($$v) {
                              _vm.$set(_vm.consignee, "actaddr", $$v)
                            },
                            expression: "consignee.actaddr",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.supervisors.length > 0
      ? _c("div", { staticClass: "order-section order-contacts" }, [
          _vm._m(13),
          _vm._v(" "),
          _c("div", { staticClass: "order-section-content" }, [
            _c("br"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        В соответствии с настройками вашего аккаунта, ваш заказ должен\n        быть одобрен одним из указанных пользователей.\n        Выберите, к кому из них вы хотите отправить заказ на проверку.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col col-12" },
                _vm._l(_vm.supervisors, function (s) {
                  return _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.moderator,
                            expression: "moderator",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "moderator",
                          disabled: _vm.loading,
                        },
                        domProps: {
                          value: s.id,
                          checked: _vm._q(_vm.moderator, s.id),
                        },
                        on: {
                          change: function ($event) {
                            _vm.moderator = s.id
                          },
                        },
                      }),
                      _vm._v(
                        "\n              " + _vm._s(s.label) + "\n            "
                      ),
                    ]),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col col-12 checkboxes" }, [
                _c("div", { staticClass: "checkbox" }, [
                  _c("label", { staticClass: "checkbox__label" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.hascommenttomoder,
                          expression: "hascommenttomoder",
                        },
                      ],
                      attrs: { type: "checkbox", disabled: _vm.loading },
                      domProps: {
                        checked: Array.isArray(_vm.hascommenttomoder)
                          ? _vm._i(_vm.hascommenttomoder, null) > -1
                          : _vm.hascommenttomoder,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.hascommenttomoder,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.hascommenttomoder = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.hascommenttomoder = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.hascommenttomoder = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm._m(14),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.hascommenttomoder
                ? _c("div", { staticClass: "col col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(_setup.FloatingInput, {
                          attrs: {
                            label: "Комментарий модератору",
                            disabled: _vm.loading,
                            name: "comment_to_moder",
                          },
                          model: {
                            value: _vm.form.comment_to_moder,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "comment_to_moder", $$v)
                            },
                            expression: "form.comment_to_moder",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("p", [
      _c(
        "button",
        {
          staticClass: "btn btn-primary gt-order_button",
          attrs: { type: "submit", disabled: _vm.loading || !_vm.isFormValid },
        },
        [_vm._v("Отправить заказ")]
      ),
    ]),
    _vm._v(" "),
    _vm.responseError
      ? _c("p", { staticClass: "order-error" }, [
          _vm._v(
            "Произошла неизвестная ошибка, возможно сервер в данный момент недоступен. Попробуйте отправить заказ позже, или оформите заказ по телефону"
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "order-section-header" }, [
      _c("div", { staticClass: "order-section-header__l" }, [
        _vm._v("Зарегистрироваться?"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "order-section-header__r" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "order-section-header" }, [
      _c("div", { staticClass: "order-section-header__l" }, [
        _vm._v("Контакты и согласование заказа"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "order-section-header__r" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "radio-button-with-icon__content" }, [
      _c("span", { staticClass: "radio-button-with-icon__icon" }, [
        _c("img", { attrs: { src: "/img/icons/payment-cash.png", alt: "" } }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "radio-button-with-icon__text" }, [
        _vm._v("Оплата наличными при получении"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "radio-button-with-icon__content" }, [
      _c("span", { staticClass: "radio-button-with-icon__icon" }, [
        _c("img", {
          attrs: { src: "/img/icons/payment-cashless.png", alt: "" },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "radio-button-with-icon__text" }, [
        _vm._v("Оплата по безналичному расчету"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "checkbox__content" }, [
      _c("span", { staticClass: "checkbox__icon" }),
      _vm._v(" "),
      _c("span", { staticClass: "checkbox__text" }, [
        _vm._v("Отправить счет по электронной почте"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "checkbox__content" }, [
      _c("span", { staticClass: "checkbox__icon" }),
      _vm._v(" "),
      _c("span", { staticClass: "checkbox__text" }, [
        _vm._v("Отправить счет по факсу"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "checkbox__content" }, [
      _c("span", { staticClass: "checkbox__icon" }),
      _vm._v(" "),
      _c("span", { staticClass: "checkbox__text" }, [
        _vm._v("Дополнительные пожелания"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "order-section-header" }, [
      _c("div", { staticClass: "order-section-header__l" }, [
        _vm._v("Адрес доставки"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "order-section-header__r" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "order-section-header" }, [
      _c("div", { staticClass: "order-section-header__l" }, [
        _vm._v("Оформление документов"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "order-section-header__r" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "checkbox__content" }, [
      _c("span", { staticClass: "checkbox__icon" }),
      _vm._v(" "),
      _c("span", { staticClass: "checkbox__text" }, [
        _vm._v("Фактический адрес отличается от юридического"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "checkbox__content" }, [
      _c("span", { staticClass: "checkbox__icon" }),
      _vm._v(" "),
      _c("span", { staticClass: "checkbox__text" }, [
        _vm._v("Грузополучатель отличается от текущего"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "order-section-header" }, [
      _c("div", { staticClass: "order-section-header__l" }, [
        _vm._v("Грузополучатель"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "order-section-header__r" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "checkbox__content" }, [
      _c("span", { staticClass: "checkbox__icon" }),
      _vm._v(" "),
      _c("span", { staticClass: "checkbox__text" }, [
        _vm._v("Фактический адрес отличается от юридического"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "order-section-header" }, [
      _c("div", { staticClass: "order-section-header__l" }, [
        _vm._v("Модераторы"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "order-section-header__r" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "checkbox__content" }, [
      _c("span", { staticClass: "checkbox__icon" }),
      _vm._v(" "),
      _c("span", { staticClass: "checkbox__text" }, [
        _vm._v("Комментарий модератору"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }