var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "floating-input" },
    [
      _vm.isTextarea
        ? [
            _c("textarea", {
              staticClass: "form-control",
              class: { "has-value": _vm.hasValue },
              attrs: {
                disabled: _vm.disabled,
                required: _vm.required,
                name: _vm.name,
                rows: "3",
                title: _vm.tooltip || _vm.label,
              },
              domProps: { value: _vm.value },
              on: {
                input: function ($event) {
                  return _vm.updateValue($event.target.value)
                },
                blur: _vm.handleBlur,
                focus: _vm.handleFocus,
              },
            }),
          ]
        : [
            _c("input", {
              staticClass: "form-control",
              class: { "has-value": _vm.hasValue },
              attrs: {
                type: _vm.type,
                disabled: _vm.disabled,
                inputmode: _vm.inputmode,
                minlength: _vm.minlength,
                maxlength: _vm.maxlength,
                name: _vm.name,
                title: _vm.tooltip || _vm.label,
                required: _vm.required,
              },
              domProps: { value: _vm.value },
              on: {
                input: function ($event) {
                  return _vm.updateValue($event.target.value)
                },
                blur: _vm.handleBlur,
                focus: _vm.handleFocus,
              },
            }),
          ],
      _vm._v(" "),
      _c("label", { class: { "has-value": _vm.hasValue } }, [
        _vm._v("\n\t\t" + _vm._s(_vm.shortLabel || _vm.label) + "\n\t\t"),
        _vm.required
          ? _c("span", { staticClass: "required" }, [_vm._v("*")])
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }